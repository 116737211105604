import { UnknownAction } from 'redux';

// action constants

export const USER__STORE_CURRENT_JWT_TOKEN = 'USER__STORE_CURRENT_JWT_TOKEN';
export const USER__DELETE_CURRENT_JWT_TOKEN = 'USER__DELETE_CURRENT_JWT_TOKEN';
export const USER__FETCH_CONTACT_DETAILS = 'USER__FETCH_CONTACT_DETAILS';
export const USER__FETCH_CONTACT_DETAILS_COMPLETE = 'USER__FETCH_CONTACT_DETAILS_COMPLETE';
export const USER__UPDATE_CONTACT_DETAILS = 'USER__UPDATE_CONTACT_DETAILS';
export const USER__UPDATE_CONTACT_DETAILS_COMPLETE = 'USER__UPDATE_CONTACT_DETAILS_COMPLETE';
export const USER__CREATE_PASSWORD = 'USER__CREATE_PASSWORD';
export const USER__CREATE_PASSWORD_COMPLETE = 'USER__CREATE_PASSWORD_COMPLETE';
export const USER__UPDATE_STATE = 'USER__UPDATE_STATE';

export interface UserState {
  token: null | string;
  title: null | string;
  firstName: null | string;
  lastName: null | string;
  address1: null | string;
  address2: null | string;
  town: null | string;
  ukPostcode: null | string;
  emailAddress: null | string;
  telephoneNumber: null | string;
  altTelephoneNumber: string | null;
  customerAccountId: string | null;
  accountLinkSuccess: boolean | null;
}

export const defaultUserState: UserState = {
  token: null,
  title: null,
  firstName: null,
  lastName: null,
  address1: null,
  address2: null,
  town: null,
  ukPostcode: null,
  emailAddress: null,
  telephoneNumber: null,
  altTelephoneNumber: null,
  customerAccountId: null,
  accountLinkSuccess: null,
};

export interface Action extends UnknownAction {
  type: string;
  payload?: any;
  meta?: Object;
  error?: boolean;
}

interface CreatePasswordPayload{
  emailAddress: string,
  password: string,
  token: string
}

export const createPassword = (payload: CreatePasswordPayload, meta = {}): Action => ({
  type: USER__CREATE_PASSWORD,
  payload,
  meta,
});

// action creators
export const storeCurrentUserInfo = (currentUserInfo): Action => ({
  type: USER__STORE_CURRENT_JWT_TOKEN,
  payload: currentUserInfo,
});

export const deleteCurrentUserInfo = (): Action => ({
  type: USER__DELETE_CURRENT_JWT_TOKEN,
});

export const fetchCurrentContactDetails = (): Action => ({
  type: USER__FETCH_CONTACT_DETAILS,
});

export const fetchCurrentContactDetailsComplete = (contactDetails: Object | Error): Action => ({
  type: USER__FETCH_CONTACT_DETAILS_COMPLETE,
  payload: contactDetails,
  error: contactDetails instanceof Error,
});

export const updateCurrentContactDetails = (payload, meta = {}): Action => ({
  type: USER__UPDATE_CONTACT_DETAILS,
  payload,
  meta,
});

export const updateCurrentContactDetailsComplete = (contactDetails): Action => ({
  type: USER__UPDATE_CONTACT_DETAILS_COMPLETE,
  payload: contactDetails,
  error: contactDetails instanceof Error,
});

export const updateUserState = (state): Action => ({
  type: USER__UPDATE_STATE,
  payload: state,
});

export default (prevState = defaultUserState, action) => {
  switch (action.type) {
    case USER__STORE_CURRENT_JWT_TOKEN: {
      try {
        const {
          signInUserSession: { accessToken, idToken } = { accessToken: {}, idToken: {} },
        } = action.payload || {};

        return {
          ...prevState,
          token: accessToken.jwtToken || idToken.jwtToken || null,
        };
      } catch {
        return defaultUserState;
      }
    }
    case USER__DELETE_CURRENT_JWT_TOKEN:
      return defaultUserState;

    case USER__FETCH_CONTACT_DETAILS_COMPLETE: {
      const contactDetails = action.payload;

      if (action.error) {
        return prevState;
      }

      return {
        ...prevState,
        ...contactDetails,
      };
    }

    case USER__UPDATE_CONTACT_DETAILS_COMPLETE: {
      const contactDetails = action.payload;

      if (action.error) {
        return prevState;
      }

      // here I would like to update another resource called UI
      // which represents my Global page message
      // e.g. error message at the top of the page

      return {
        ...prevState,
        ...contactDetails,
      };
    }

    case USER__UPDATE_STATE: {
      return {
        ...prevState,
        ...action.payload,
      };
    }

    default:
      return prevState;
  }
};
