import React, { ReactNode, Ref, forwardRef } from 'react';
import { DropdownMenu } from '@simplybusiness/mobius';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

interface MenuItemProps {
  href: string;
  onClick?: () => void;
  children: ReactNode;
}

const StyledLink = styled(Link)`
  color: var(--color-text-medium);
  text-decoration: none;
`;

const MenuItem = forwardRef((props: MenuItemProps, ref: Ref<HTMLLIElement>) => {
  const {
    href, onClick, children, ...rest
  } = props;
  const isActive = window.location.pathname === href;

  return (
    <DropdownMenu.Item ref={ref} {...rest} active={isActive}>
      <StyledLink to={href} onClick={onClick}>
        {children}
      </StyledLink>
    </DropdownMenu.Item>
  );
});

export default MenuItem;
